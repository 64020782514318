import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrudTableComponent } from './crudTable.component';

@NgModule({
    imports: [
        CommonModule
     ],
    declarations: [
        CrudTableComponent
    ],
    exports: [
        CrudTableComponent
    ]
})

export class CrudTableModule {}