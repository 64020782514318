import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { DatepickerOptions } from 'ng2-datepicker';

import * as esLocale from 'date-fns/locale/es';
import * as moment from 'moment';


@Component({
  selector: 'app-modal-content',
  templateUrl: 'add-modal.rcomponent.html',
})

export class AddModelComponent implements OnInit {

  keys: Array<string>;
  keysObjs: any;

  options: any;
  optionsKeys: Array<string>;

  title: String;
  blckAdd: Array<string>;

  form: any = {};
  addMode: Boolean;

  originalForm: any = {};
  statusAction: string;

  onSubmit: Subject<string>;
  onEdit: Subject<string>;
  onChange: Subject<string>;

  addDoc: Boolean;
  docName: string;

  docAdded: Boolean;
  onDocUploaded: Subject<FormData>;
  formData: FormData;

    optionsDatePicker: DatepickerOptions = {
      minYear: 2018,
      maxYear: 2030,
      displayFormat: 'MMM D[,] YYYY',
      barTitleFormat: 'MMMM YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
      locale: esLocale,
      barTitleIfEmpty: 'Seleccione fecha:',
      placeholder: 'Seleccione fecha:',
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: { "width": "100%" }, // Optional, value to pass to [ngStyle] on the input field
      useEmptyBarTitle: false, // Defaults t
    } 

  

  constructor() { }

  ngOnInit() {

    this.optionsKeys = _.keys(this.options);
    this.keys = _.difference(_.keys(this.keysObjs), this.blckAdd);

    this.onSubmit = new Subject();
    this.onEdit = new Subject();
    this.onChange = new Subject();
    this.onDocUploaded = new Subject();

    this.originalForm = _.clone(this.form);

    this.docAdded = this.addDoc === true ? false : true;
    this.formData = new FormData();
  }

  submitForm() {

    //VALIDAR
    let formToPass: any = {};

    _.keys(this.form).forEach(key => {
      if (this.keysObjs[key]["formato"] == 'fecha') {
        formToPass[key] = moment(this.form[key]).format('DD/MM/YY')
      }
      else {
        formToPass[key] = this.form[key];
      }
    });

    this.onSubmit.next(formToPass);

  }

  editForm() {
    // compare and remove from the object matching values except the id
    let keysToRemove = [];
    const originalFormLocal = this.originalForm;

    _.mapKeys(this.form, function (value, key) {
      if (value === originalFormLocal[key]) {
        keysToRemove.push(key);
      }
    });
    keysToRemove = _.difference(keysToRemove, ['id']);
    const formOfChanges = _.omit(this.form, keysToRemove);

    this.onEdit.next(formOfChanges);

  }

  changeStatus() {
    this.onChange.next();
  }

  uploadFile(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.formData.append(this.docName, file, file.name);
      this.docAdded = true;
      this.onDocUploaded.next(this.formData);
    }
  }



}
