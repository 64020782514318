import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class LoginService {
    constructor(public http: HttpClient) {

    }

    registrar(datos) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.api + 'registro', datos)
                .subscribe((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }

    cambiarPass(datos) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.api + 'login/cambiarPass', datos)
                .subscribe((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }

    doLogin(datos) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.api + 'login/', datos)
                .subscribe((response: any) => {
                    if (!response.error) {
                        //NOTE: EN LUGAR DE USAR LOCALSTORAGE USAR LA LIBRERIA COOKIE Y ENCRIPTAR
                        window.localStorage.setItem('token', response['token']);
                        window.localStorage.setItem('accessLevel', response['accessLevel']);
                    }
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }

    environmentiarCorreo(datos) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.api + 'login/environmentiarCorreo', datos)
                .subscribe((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }
}
