import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { LoginService } from '../providers/login.service'
import Swal from 'sweetalert2'
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  providers: [LoginService],
})
export class RecuperarContrasenaComponent implements OnInit {

  constructor(public loginService: LoginService, public router: Router, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, ) { }
  form: any = {}
  idToken
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idToken = params['idToken']
    })

  }

  registrar() {
    if (this.form.password != this.form.password2) {
      Swal.fire("", "Las contraseñas no coinciden", "error")
    } else {
      this.form.token = this.idToken
      this.loginService.cambiarPass(this.form)
        .then((msj: any) => {
          Swal.fire(
            '',
            msj.msg,
            msj.check
          )
          if (msj.check == 'success') this.router.navigate(['/']);
        })
    }
  }

}
