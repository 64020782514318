import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html'
})
export class TerminosComponent implements OnInit {

  constructor(public router: Router) {
    window.scrollTo(0, 0)
   }

  ngOnInit() {
  }

  regresar(){
    this.router.navigateByUrl('/', { state: {registrarse: true} });
  }

}
