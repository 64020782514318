import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../providers/admin.service'
import { UserService } from '../providers/user.service'
import { Subject } from 'rxjs'
import { NgxSpinnerService } from "ngx-spinner"
import Swal from 'sweetalert2'
import img from '../../assets/images/imagenesBase64'
declare var jsPDF: any
import _ from 'lodash'


@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./style.scss'],
  providers: [AdminService, UserService]
})
export class UsuarioComponent implements OnInit {

  constructor(public userService: UserService, public adminService: AdminService, public router: Router, private spinner: NgxSpinnerService) {

  }
  onDocUploaded: Subject<FormData>;
  formData: FormData;
  form: any = {}
  usuario
  importaciones: any = []
  ocultarTabla = false
  doc = new jsPDF('landscape')
  mostrarTabla = false
  links: any = {}

  ngOnInit() {
    this.getData()
  }

  async getData() {
    this.links.mostrar = false
    this.usuario = await this.userService.getUsuario()
    this.importaciones = await this.adminService.getData()
    this.importaciones = this.importaciones.sort((a, b) => (b.id) - (a.id))
    let registrosBD: any = []
    registrosBD = await this.userService.getRegistros()
    this.importaciones.forEach(importacion => {
      importacion.registro = {
        totalPeras: 0,
        ImportacionesId: importacion.id,
        UsuariosId: this.usuario.id
      }
      registrosBD.forEach(registrobd => {
        if (registrobd.ImportacionesId == importacion.id) {
          importacion.registro = registrobd
        }
      })
      window.scrollTo(0, 0)
    })
  }

  formatNumber(n, c, d = '.', t = ',') {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (j = i.length) > 3 ? j % 3 : 0;

    return (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - Number(i)).toFixed(c).slice(2) : "");
  };

  verificar24Quincenas() {
    if (this.importaciones.length > 24) {
      let aux = this.importaciones.length - 24
      for (let index = this.importaciones.length - 1; aux > 0; aux--) {
        this.importaciones[index].habilitar = false
        index--
      }
    }
  }

  actualizarRegistros() {
    let registros = _.map(this.importaciones, importacion => importacion.registro)
    this.userService.actulizar(registros)
      .then((msg: any) => {
        this.getData()
        Swal.fire({
          icon: msg.check,
          title: msg.msg,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }

  redondear(num) {
    return Math.round(num * 100) / 100
  }

  getShare(totalPeras, cantidad_peras) {
    let share = this.redondear((totalPeras * 100) / cantidad_peras)
    return isNaN(share) ? '0 %' : share + ' %'
  }

  generarPDF() {
    this.spinner.show();
    let perasLogo = img.peraslogo
    let mandado = img.mandado
    let rows = this.importaciones.map(importacion => {
      return ['#' + importacion.anio + '-' + importacion.quincena, importacion.fecha, this.formatNumber(importacion.cantidad_peras, '0'), this.formatNumber(importacion.registro.totalPeras, '0'), this.getShare(importacion.registro.totalPeras, importacion.cantidad_peras)]
    })
    let columns = [["Quincena", "Fecha", "Total País por Quincena", "Total Cajas Importadas por Quincena", "Participación de Mercado"]];
    let doc = new jsPDF();
    doc.autoTable({
      head: columns,
      body: rows,
      theme: 'striped',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [131, 190, 79]
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'right',
      },
      columnStyles: {
        0: { columnWidth: 20 },
        1: { columnWidth: 40 },
        2: { columnWidth: 40 },
        3: { columnWidth: 40 },
        4: { columnWidth: 40 }
      },
      didDrawPage: function (data) {
        doc.addImage(perasLogo, 'JPEG', 15, 10, 20, 25)// margen Izq , margen Top , Largo , Alto
        doc.addImage(mandado, 'JPEG', 70, 8, 110, 26)
        doc.setFontStyle('normal')
        doc.setFontSize(18)
        doc.text('Reporte de ventas', 80, 50)
        // Footer
        var str = 'Página ' + doc.internal.getNumberOfPages()
        doc.setFontSize(10)
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
      },
      margin: { top: 60 }
    })
    doc.save('table.pdf')
    this.spinner.hide();

  }

  compartir() {
    this.spinner.show();
    let pdfTransporter = new FormData();
    let perasLogo = img.peraslogo
    let mandado = img.mandado
    let rows = this.importaciones.map(importacion => {
      return ['#' + importacion.anio + '-' + importacion.quincena, importacion.fecha, this.formatNumber(importacion.cantidad_peras, '0'), this.formatNumber(importacion.registro.totalPeras, '0'), this.getShare(importacion.registro.totalPeras, importacion.cantidad_peras)]
    })
    let columns = [["Quincena", "Fecha", "Total País por Quincena", "Total Cajas Importadas por Quincena", "Participación de Mercado"]];
    let doc = new jsPDF();
    doc.autoTable({
      head: columns,
      body: rows,
      theme: 'striped',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [131, 190, 79]
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'right',
      },
      columnStyles: {
        0: { columnWidth: 20 },
        1: { columnWidth: 40 },
        2: { columnWidth: 40 },
        3: { columnWidth: 40 },
        4: { columnWidth: 40 }
      },
      didDrawPage: function (data) {
        doc.addImage(perasLogo, 'JPEG', 15, 10, 20, 25)// margen Izq , margen Top , Largo , Alto
        doc.addImage(mandado, 'JPEG', 70, 8, 110, 26)
        doc.setFontStyle('normal')
        doc.setFontSize(18)
        doc.text('Reporte de ventas', 80, 50)
        // Footer
        var str = 'Página ' + doc.internal.getNumberOfPages()
        doc.setFontSize(10)
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
      },
      margin: { top: 60 }
    })
    var blob = doc.output('blob')
    pdfTransporter.append('imagen', blob)
    this.userService.compartirResultados(pdfTransporter)
      .then((data: any) => {
        this.links.urlWa = 'Hola, te muestro mi progreso en peras usa! \n https://quenotecomanelmandado.com/' + data.link
        this.links.urlFb = 'http://www.facebook.com/sharer.php?u=https://quenotecomanelmandado.com/' + data.link
        this.links.urlCo = 'Hola, te muestro mi progreso en peras usa! \nhttps://quenotecomanelmandado.com/' + data.link
        this.links.mostrar = true
        this.spinner.hide();
      })
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('accessLevel');
    this.router.navigate(['/']);
  }


}

