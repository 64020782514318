import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';

@Component({
  selector: 'app-conoce-mas',
  templateUrl: './conoce-mas.component.html'
})
export class ConoceMasComponent implements OnInit {

  constructor(public router: Router) { }

  validador = false

  ngOnInit() {
    window.localStorage.getItem('token') ? this.validador = true : this.validador = false
    window.scrollTo(0, 0)
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('accessLevel');
    this.router.navigate(['/']);
  }

}
