import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'crudTable',
  templateUrl: './crudTable.component.html'
})

// tslint:disable-next-line:component-class-suffix
export class CrudTableComponent implements OnInit {

  @Input() public idtable: string;
  @Input() public array: Array<any>;
  @Input() public cabezeras: any;

  @Input() public actions: any;
  @Output() actionEmitter = new EventEmitter();

  keys;
  actionsKeys;

  constructor() {}

  public ngOnInit() {
    this.actions = this.actions || [];
    this.actionsKeys = Object.keys(this.actions)    
     this.keys = Object.keys(this.cabezeras);
     this.keys = this.keys.concat(this.actionsKeys);
  }

getNestedObject(nestedObj, pathArr, isCabezera) {
  let pathCabezera = pathArr;

  if (!isCabezera) {
    pathArr = pathArr.includes('.') ? pathArr.split('.') : pathArr;
    let valorCelda;

    if (typeof (pathArr) == 'object') {
      valorCelda = pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    } else {
      valorCelda = nestedObj[pathArr];
    }

    if (!this.cabezeras[pathCabezera].formato) {
      return valorCelda;
    } else if (this.cabezeras[pathCabezera].formato == "fecha") {
      return valorCelda ? moment(valorCelda).format('DD/MM/YYYY') : ''
    } else if (this.cabezeras[pathCabezera].formato == "moneda") {
      return this.formatMoney(valorCelda,'2','.',',')
    } else if (this.cabezeras[pathCabezera].formato == "numberPositivo") {
      return valorCelda
    }

  } else {
    //ES CABEZERA
    return nestedObj[pathArr].title
  }
}

formatMoney(n, c, d, t) {
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return '$' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - Number(i)).toFixed(c).slice(2) : "");
};

actionEmited(idaction, obj){
  let event = {
    action: idaction,
    params: obj
  }
  this.actionEmitter.emit(event);
}

}
