import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../providers/admin.service'
import * as moment from 'moment'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  providers: [AdminService]
})
export class AdminComponent implements OnInit {

  constructor(public adminService: AdminService, public router: Router) {

  }

  form: any = {}
  importaciones: any = []

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.adminService.getData()
      .then((importaciones) => {
        this.importaciones = importaciones
        this.importaciones = this.importaciones.sort((a, b) => (b.id) - (a.id))
        if (this.importaciones.length == 0) {
          this.importaciones.push({
            anio: moment().format('YY'),
            fecha: '',
            quincena: 1,
            cantidad_peras: 0,
          })
          return
        }
      })
  }

  agregarRegistro() {
      let año = this.importaciones[0].quincena < 24 ? this.importaciones[0].anio : (Number(moment().format('YY')) + 1)
      let quincena = this.importaciones[0].quincena < 24 ? this.importaciones[0].quincena + 1 : 1;
      this.importaciones.push({
        id: this.importaciones[0].id + 1,
        anio: año,
        fecha: '',
        quincena: quincena,
        cantidad_peras: 0,
      })
      this.importaciones = this.importaciones.sort((a, b) => (b.id) - (a.id))
  }

  actualizarImportaciones() {
      this.adminService.actulizar(this.importaciones)
        .then((msg: any) => {
          Swal.fire({
            icon: msg.check,
            title: msg.msg,
            showConfirmButton: false,
            timer: 1500
          })
          msg.check == 'success' ? this.getData() : 0
        })
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('accessLevel');
    this.router.navigate(['/']);
  }

}