import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './config/auth.interceptor';

import { NgDatepickerModule } from 'ng2-datepicker';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';

import { CrudTableModule } from './crudTable/crudTable.module';
import { AddModelComponent } from './add.modal/add-modal.rcomponent'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { AdminComponent } from './admin/admin.component';
import { ConoceMasComponent } from './conoce-mas/conoce-mas.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { PerfilComponent } from './perfil/perfil.component';
import { ResultadosComponent } from './resultados/resultados.component';
import { GraficaComponent } from './grafica/grafica.component';
import { TerminosComponent } from './terminos/terminos.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component'
import { NgxSpinnerModule } from "ngx-spinner";
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    AdminComponent,
    AddModelComponent,
    ConoceMasComponent,
    UsuarioComponent,
    PerfilComponent,
    ResultadosComponent,
    GraficaComponent,
    TerminosComponent,
    AvisoPrivacidadComponent,
    RecuperarContrasenaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CrudTableModule,
    NgDatepickerModule,
    ChartsModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    HttpClientModule,
    NgxSpinnerModule,
    RouterModule.forRoot([]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    AddModelComponent
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
