import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../providers/login.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  providers: [LoginService],

})
export class IndexComponent implements OnInit {

  form: any = {}
  validaciones: any = { login: false, olvidePass: false }

  constructor(public loginService: LoginService, public router: Router) {
    console.log(this.router.getCurrentNavigation().extras.state);
    if (this.router.getCurrentNavigation().extras.state) {
      this.validaciones.login = this.router.getCurrentNavigation().extras.state["registrarse"]
    } 
  }

  ngOnInit() {
    //validaciones.login
    window.localStorage.getItem('accessLevel') == 'peradmin' ? this.router.navigate(['/admin']) : this.router.navigate(['/usuario'])
  }

  registrar() {
    if (this.form.correo == this.form.correo2) {
      if (!this.form.password || !this.form.correo || !this.form.empresa) {
        Swal.fire("", "Llena todos los datos para registrar", "error")
      } else {
        this.loginService.registrar(this.form)
          .then((msj: any) => {
            Swal.fire(
              '',
              msj.msg,
              msj.check
            )
            if (msj.check == 'success') this.validaciones.login = false
          })
      }
    }
    else{
      Swal.fire("", "Los correos no coinciden", "error")
    }
  }

  doLogin() {
    if (!this.form.password || !this.form.correo) {
      Swal.fire("", "Llena todos los datos para iniciar sesión", "error")
    } else {
      this.loginService.doLogin(this.form)
        .then((response: any) => {
          if (response.error) {
            Swal.fire(
              '',
              response.msg,
              'error'
            )
          }
          else {
            window.localStorage.getItem('accessLevel') == 'peradmin' ? this.router.navigate(['/admin']) : this.router.navigate(['/usuario'])
          }
        })
    }
  }

  inicioSesion() {
    this.validaciones = { login: false, olvidePass: false }
  }

  cambiarValidador() {
    this.form = {}
    this.validaciones.login = !this.validaciones.login
    this.validaciones.olvidePass = false
  }

  olvideContrasena() {
    this.form = {}
    this.validaciones.olvidePass = true
  }

/*   enviarCorreo() {
    this.validaciones.loader = true
    this.loginService.enviarCorreo(this.form)
      .then((msj: any) => {
        this.validaciones.loader = false
        Swal.fire(
          '',
          msj.msg,
          msj.check
        )
        if (msj.check != 'error') {
          this.form = {}
          this.validaciones = { login: false, olvidePass: false }
        }
      })
  } */
}
