import { Component, OnInit } from '@angular/core'
import { UserService } from '../providers/user.service'
import { Router } from '@angular/router'
import * as html2canvas from 'html2canvas'
import { NgxSpinnerService } from "ngx-spinner"
import img from '../../assets/images/imagenesBase64'
declare var jsPDF: any

@Component({
  selector: 'app-grafica',
  templateUrl: './grafica.component.html',
  providers: [UserService],
  styleUrls: ['./style.scss']
})
export class GraficaComponent implements OnInit {

  constructor(public userService: UserService, public router: Router, private spinner: NgxSpinnerService) {
    window.scrollTo(0, 0)
  }
  primerTemporada = []
  segundaTemporada = []
  datos: any = {}
  links: any = {}
  formData: FormData;
  blob = new FormData()

  public barChartOptions = {
    /*  tooltips: {
       enabled: true,
       mode: 'single',
       callbacks: {
         title: (tooltipItems, data) => {
           return this.getTooltip(tooltipItems)
         }
       }
     }, */
    legend: {
      display: true,
      position: 'right',
      labels: { fontSize: 18 }
    },
    title: {
      display: true,
      text: 'Importaciones por quincena',
      fontSize: 40
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontSize: 16
        },
        scaleLabel: {
          display: true,
          labelString: 'Cajas',
          fontColor: "#8e394a",
          fontSize: 30
        }
      }],
      xAxes: [{
        ticks: {
          beginAtZero: true,
          fontSize: 16
        },
        scaleLabel: {
          display: true,
          labelString: 'Quincenas',
          fontColor: "#8e394a",
          fontSize: 30
        }
      }],
    }
  }

  public barChartLabels = [];
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData = [
    { data: [65, 11], label: 'Series A', lineTension: 0, fill: false, borderColor: 'rgba(83, 203, 29, 1)' },
    { data: [28, 40], label: 'Series B', lineTension: 0, fill: false, borderColor: 'rgba(83, 203, 29, 1)' }
  ];


  ngOnInit() {
    this.getData()
  }

  getTooltip(tooltipItems) {
    let tooltipItem = tooltipItems[0]

    if (tooltipItem.datasetIndex == 0) {
      return this.datos["temporadaAnterior"][tooltipItem.index]["Importaciones"]["fecha"]
    }
    else if (tooltipItem.datasetIndex == 1) {
      return this.datos["nuevaTemporada"][tooltipItem.index]["Importaciones"]["fecha"]
    }

  }

  getData() {
    this.spinner.show()
    this.links.mostrar = false
    this.userService.getDatosGrafica()
      .then((data: any) => {
        this.datos[0] = data[0].reverse()
        this.datos[1] = data[1].reverse()
        let primerArray = []
        let segundoArray = []
        this.datos[1].forEach(reporte => {
          primerArray.push(reporte.totalPeras)
        })
        this.datos[0].forEach(reporte => {
          segundoArray.push(reporte.totalPeras)
          this.barChartLabels.push('Quincena - ' + reporte.Importaciones.quincena)
        })
        this.barChartData = [
          { data: primerArray, label: 'Anterior', lineTension: 0, fill: false, borderColor: 'rgba(26, 26, 26, 1)' },
          { data: segundoArray, label: 'Actual', lineTension: 0, fill: false, borderColor: 'rgba(131, 190, 79, 1)' }
        ]
        this.spinner.hide();
      })
      .catch((data: any) => {
        this.spinner.hide();
      })
  }

  generarPDF(descargar) {
    this.spinner.show()
    let canvas = <HTMLCanvasElement>document.getElementById('contenido')
    var grafica = canvas.toDataURL("image/png")
    let doc = new jsPDF()
    let perasLogo = img.peraslogo
    let mandado = img.mandado
    // Header
    doc.addImage(perasLogo, 'JPEG', 20, 10, 25, 30)// margen Izq , margen Top , Largo , Alto ,
    doc.addImage(mandado, 'JPEG', 60, 10, 140, 30)
    //Body
    doc.addImage(grafica, 'JPG', 6, 55, 200, 105);// margen Izq , margen Top , Largo , Alto ,
    // Footer
    var str = 'Página ' + doc.internal.getNumberOfPages()
    doc.setFontSize(10)
    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 14, pageHeight - 10)
    var toBlob = doc.output('blob')
    this.blob.append('imagen', toBlob)
    if (descargar == 'descargar') {
      doc.save('grafica.pdf')
      this.spinner.hide()
    } else {
      this.generarPdfCompartir()
    }
  }

  generarPdfCompartir() {
    this.userService.compartirResultados(this.blob)
      .then((data: any) => {
        this.links.urlWa = encodeURI('Hola, te muestro mi progreso en peras usa! \n https://quenotecomanelmandado.com/' + data.link + '\n Calcula el tuyo entrando a www.quenotecomanelmandado.com')
        this.links.urlFb = 'http://www.facebook.com/sharer.php?u=https://quenotecomanelmandado.com/' + data.link
        this.links.urlCo = encodeURI('Hola, te muestro mi progreso en peras usa! \nhttps://quenotecomanelmandado.com/' + data.link + '\n Calcula el tuyo entrando a www.quenotecomanelmandado.com')
        this.links.mostrar = true
        this.spinner.hide()
      })
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('accessLevel');
    this.router.navigate(['/'])
  }

}
