import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = window.localStorage.getItem('token');
    let nextReq;
    if (token) {
      nextReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    } else {
      nextReq = req;
    }
    return next.handle(nextReq);
  }
}