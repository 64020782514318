import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
  constructor(public http: HttpClient) {

  }

  getData() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'admin')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  getRegistros() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'usuario/getRegistros')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  actulizar(datos) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.api + 'usuario/actualizar', datos)
        .subscribe((response) => {
          resolve(response)
        }, (err) => {
          reject(err)
        });
    });
  }

  getUsuario() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'usuario/getUsuario')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  getUsuarioLogin() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'usuario/getUsuarioLogin')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  getInformacionPorcentual() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'usuario/getInformacionPorcentual')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  compartirResultados(datos) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.api + 'usuario/compartirResultados', datos)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    })
  }

  reporteTabla() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'usuario/reporteTabla')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });

  }

  getDatosGrafica() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + 'usuario/getDatosGrafica')
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  actualizarDatos(datos) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.api + 'usuario/actualizarDatos', datos)
        .subscribe((response) => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

}
