import { Component } from '@angular/core';
import { Router } from '@angular/router'
import { UserService } from '../providers/user.service'
import { NgxSpinnerService } from "ngx-spinner"
import { trigger, transition, style, animate } from '@angular/animations'
import img from '../../assets/images/imagenesBase64'

declare var jsPDF: any

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  providers: [UserService],
  styleUrls: ['./style.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class ResultadosComponent {
  formData: FormData;
  fileList: FileList
  datos = {}
  reportes: any = {}
  links: any = {}
  validacion: any = {}
  visible = false

  constructor(public userService: UserService, public router: Router, private spinner: NgxSpinnerService) {
    this.getData()
  }

  getData() {
    window.scrollTo(0, 0)
    this.links.mostrar = false
    this.spinner.show();
    this.userService.getInformacionPorcentual()
      .then((data: any) => {
        console.log('data', data);
        this.datos = data
        if(this.datos[0].length > 0) {
          this.primerReporte(this.datos[0])
        }
        if(this.datos[1].length > 0) {
          this.segundoReporte(this.datos[1])
        }
        if(this.datos[2].length > 0) {
          this.tercerReporte(this.datos[2])
        }
        this.spinner.hide();
      })
  }

  primerReporte(arrayRegistros) {
    this.reportes.quincenaActual = arrayRegistros[0].Importaciones.anio + '-' + arrayRegistros[0].Importaciones.quincena
    let quincenaActual = (arrayRegistros[0].totalPeras * 100) / arrayRegistros[0].Importaciones.cantidad_peras
    this.reportes.actual = this.redondear(quincenaActual)
    this.reportes.actual = isFinite(this.reportes.actual) ? this.reportes.actual : '0'

    let quincenaAnterior = ((arrayRegistros[1] ? arrayRegistros[1].totalPeras : 1) * 100) / (arrayRegistros[1] ? arrayRegistros[1].Importaciones.cantidad_peras : 1)
    this.reportes.primero = this.redondear(((quincenaActual / quincenaAnterior) - 1) * 100)
    this.reportes.primero > 0 ? this.validacion.primera = true : this.validacion.primera = false
    this.reportes.primero = isFinite(this.reportes.primero) ? this.reportes.primero : 0
  }

  segundoReporte(arrayRegistros) {
    let quincenaActual = (arrayRegistros[0].totalPeras * 100) / arrayRegistros[0].Importaciones.cantidad_peras
    let quincenaAnterior = ((arrayRegistros[1] ? arrayRegistros[1].totalPeras : 1) * 100) / (arrayRegistros[1] ? arrayRegistros[1].Importaciones.cantidad_peras : 1)
    this.reportes.segundo = this.redondear(((quincenaActual / quincenaAnterior) - 1) * 100)
    this.reportes.segundo > 0 ? this.validacion.segunda = true : this.validacion.segunda = false
    this.reportes.segundo = isFinite(this.reportes.segundo) ? this.reportes.segundo : 0
  }

  tercerReporte(arrayRegistros) {
    let temporadaActual = { totalPeras: 0, importacionPeras: 0 }
    let temporadaPasada = { totalPeras: 0, importacionPeras: 0 }
    arrayRegistros[0].forEach(actual => {
      temporadaActual.totalPeras += Number(actual.totalPeras)
    })
    temporadaActual.importacionPeras = arrayRegistros[2]
    arrayRegistros[1].forEach(pasada => {
      if(pasada) {
        temporadaPasada.totalPeras += Number(pasada.totalPeras)
        temporadaPasada.importacionPeras += Number(pasada.Importaciones.cantidad_peras)
      }
    })
    let quincenaActual = (temporadaActual.totalPeras * 100) / temporadaActual.importacionPeras
    let quincenaAnterior = (temporadaPasada.totalPeras * 100) / temporadaPasada.importacionPeras
    this.reportes.tercero = ((quincenaActual / quincenaAnterior) - 1) * 100
    this.reportes.tercero = this.redondear(((quincenaActual / quincenaAnterior) - 1) * 100)
    this.reportes.tercero > 0 ? this.validacion.tercera = true : this.validacion.tercera = false
    this.reportes.tercero = isFinite(this.reportes.tercero) ? this.reportes.tercero : 0
  }

  verParticipacion() {
    this.visible = !this.visible
  }

  redondear(num) {
    return Math.round(num * 100) / 100
  }

  reporteTabla() {
    this.userService.reporteTabla()
      .then((data: any) => {
      })
  }

  compartir() {
    let pdfTransporter = new FormData();
    let perasLogo = img.peraslogo
    let mandado = img.mandado
    let flechaVerde = img.flechaVerde
    let flechaRoja = img.flechaRoja
    let doc = new jsPDF()
    let ejeY = 30

    // Header
    doc.addImage(perasLogo, 'JPEG', 15, 10, 20, 25)// margen Izq , margen Top , Largo , Alto
    doc.addImage(mandado, 'JPEG', 70, 8, 110, 26)
    //Body
    ejeY += 45 // = 75
    doc.setFontSize(16)
    doc.text(20, ejeY, 'Tu participación de mercado / Market Share a nivel país es:')
    ejeY += 30 // = 105
    doc.setFontSize(64)
    doc.setFontType("bold");
    doc.setTextColor(142, 57, 74)
    doc.text(20, ejeY + 10, this.reportes.actual + '%')
    ejeY += 50 // = 125
    doc.setFontSize(14)
    doc.setTextColor(100)

    this.reportes.primero > 0 ? doc.setTextColor(0, 104, 55) : doc.setTextColor(192, 21, 33)
    this.reportes.primero > 0 ? doc.addImage(flechaVerde, 'JPEG', 15, ejeY - 8, 7, 10) : doc.addImage(flechaRoja, 'JPEG', 15, ejeY - 8, 7, 10)
    doc.text(25, ejeY, this.reportes.primero + '%')
    doc.setTextColor(100)
    doc.text(48, ejeY, 'Con respecto a la quincena pasada')

    ejeY += 20 // =
    this.reportes.segundo > 0 ? doc.setTextColor(0, 104, 55) : doc.setTextColor(192, 21, 33)
    this.reportes.segundo > 0 ? doc.addImage(flechaVerde, 'JPEG', 15, ejeY - 8, 7, 10) : doc.addImage(flechaRoja, 'JPEG', 15, ejeY - 8, 7, 10)
    doc.text(25, ejeY, this.reportes.segundo + '%')
    doc.setTextColor(100)
    doc.text(48, ejeY, 'Con respecto al misma quincena de la temporada pasada')

    ejeY += 20 // =
    this.reportes.tercero > 0 ? doc.setTextColor(0, 104, 55) : doc.setTextColor(192, 21, 33)
    this.reportes.tercero > 0 ? doc.addImage(flechaVerde, 'JPEG', 15, ejeY - 8, 7, 10) : doc.addImage(flechaRoja, 'JPEG', 15, ejeY - 8, 7, 10)
    doc.text(25, ejeY, this.reportes.tercero + '%')
    doc.setTextColor(100)
    doc.text(48, ejeY, 'En comparación con la temporada pasada hasta esta quincena')

    // Footer
    var str = 'Página ' + doc.internal.getNumberOfPages()
    doc.setFontSize(10)
    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 14, pageHeight - 10)
    var blob = doc.output('blob')
    pdfTransporter.append('imagen', blob)
    this.userService.compartirResultados(pdfTransporter)
      .then((data: any) => {
        this.links.urlWa = 'Hola, te muestro mi progreso en peras usa! \n https://quenotecomanelmandado.com/' + data.link
        this.links.urlFb = 'http://www.facebook.com/sharer.php?u=https://quenotecomanelmandado.com/' + data.link
        this.links.urlCo = 'Hola, te muestro mi progreso en peras usa! \nhttps://quenotecomanelmandado.com/' + data.link
        this.links.mostrar = true
      })
  }

  descargar() {

    let perasLogo = img.peraslogo
    let mandado = img.mandado
    let flechaVerde = img.flechaVerde
    let flechaRoja = img.flechaRoja
    let doc = new jsPDF()
    let ejeY = 30

    // Header
    doc.addImage(perasLogo, 'JPEG', 15, 10, 20, 25)// margen Izq , margen Top , Largo , Alto
    doc.addImage(mandado, 'JPEG', 70, 8, 110, 26)
    doc.setTextColor(100)

    //Body
    ejeY += 38 // = 70
    doc.setFontSize(16)
    doc.text(20, ejeY, 'Quincena: ' + this.datos[0][0]["Importaciones"]["fecha"])
    ejeY += 7 // = 75
    doc.text(20, ejeY, 'Tu participación de mercado / Market Share a nivel país es:')
    ejeY += 30 // = 105
    doc.setFontSize(64)
    doc.setFontType("bold");
    doc.setTextColor(142, 57, 74)
    doc.text(20, ejeY + 10, this.reportes.actual + '%')
    ejeY += 50 // = 125
    doc.setFontSize(14)
    doc.setTextColor(100)

    this.reportes.primero > 0 ? doc.setTextColor(0, 104, 55) : doc.setTextColor(192, 21, 33)
    this.reportes.primero > 0 ? doc.addImage(flechaVerde, 'JPEG', 15, ejeY - 8, 7, 10) : doc.addImage(flechaRoja, 'JPEG', 15, ejeY - 8, 7, 10)
    doc.text(25, ejeY, this.reportes.primero + '%')
    doc.setTextColor(100)
    doc.text(48, ejeY, 'Con respecto a la quincena pasada')

    ejeY += 20 // =
    this.reportes.segundo > 0 ? doc.setTextColor(0, 104, 55) : doc.setTextColor(192, 21, 33)
    this.reportes.segundo > 0 ? doc.addImage(flechaVerde, 'JPEG', 15, ejeY - 8, 7, 10) : doc.addImage(flechaRoja, 'JPEG', 15, ejeY - 8, 7, 10)
    doc.text(25, ejeY, this.reportes.segundo + '%')
    doc.setTextColor(100)
    doc.text(48, ejeY, 'Con respecto al misma quincena de la temporada pasada')

    ejeY += 20 // =
    this.reportes.tercero > 0 ? doc.setTextColor(0, 104, 55) : doc.setTextColor(192, 21, 33)
    this.reportes.tercero > 0 ? doc.addImage(flechaVerde, 'JPEG', 15, ejeY - 8, 7, 10) : doc.addImage(flechaRoja, 'JPEG', 15, ejeY - 8, 7, 10)
    doc.text(25, ejeY, this.reportes.tercero + '%')
    doc.setTextColor(100)
    doc.text(48, ejeY, 'En comparación con la temporada pasada hasta esta quincena')

    // Footer
    var str = 'Página ' + doc.internal.getNumberOfPages()
    doc.setFontSize(10)
    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, 14, pageHeight - 10)
    doc.save('reportes.pdf')
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('accessLevel');
    this.router.navigate(['/']);
  }
}
