// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()

export class RoleGuardService implements CanActivate {
  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    // this will be passed from the route config
    // on the data property
    const expectedLevel = route.data.expectedLevel;

    const token = localStorage.getItem('token');
    const accessLevel = localStorage.getItem('accessLevel');

    if (!token || accessLevel !== expectedLevel) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}
