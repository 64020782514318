import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../providers/user.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  providers: [UserService]
})
export class PerfilComponent implements OnInit {

  constructor(public userService: UserService, public router: Router) { }

  usuario: any = {}

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.userService.getUsuarioLogin()
      .then((usuario) => {
        this.usuario = usuario
        delete this.usuario.password
      })
  }

  actualizarDatos() {
    if (this.usuario.password) {
      this.userService.actualizarDatos(this.usuario)
        .then((msj: any) => {
          Swal.fire(
            '',
            msj.msg,
            msj.check
          )
          this.getData()
        })
    }
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('accessLevel');
    this.router.navigate(['/']);
  }

}
