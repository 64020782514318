import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from './config/accessLevelGuard';
import { UsuarioComponent } from './usuario/usuario.component'
import { IndexComponent } from "./index/index.component";
import { AdminComponent } from "./admin/admin.component";
import { PerfilComponent } from "./perfil/perfil.component";
import { ConoceMasComponent } from './conoce-mas/conoce-mas.component'
import { ResultadosComponent } from './resultados/resultados.component'
import { GraficaComponent } from './grafica/grafica.component'
import { TerminosComponent } from './terminos/terminos.component'
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component'
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component'

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: {
      title: 'Inicio'
    }
  },
  {
    path: 'recuperacion',
    component: RecuperarContrasenaComponent,
    data: {
      title: 'Recuperaracion'
    }
  },
  {
    path: 'recuperacion/:idToken',
    component: RecuperarContrasenaComponent,
  },
  {
    path: 'conocemas',
    component: ConoceMasComponent,
    data: {
      title: 'ConoceMas'
    }
  },
  {
    path: 'terminos',
    component: TerminosComponent,
    data: {
      title: 'Terminos'
    }
  },
  {
    path: 'privacidad',
    component: AvisoPrivacidadComponent,
    data: {
      title: 'Privacidad'
    }
  },
  {
    path: 'resultados',
    component: ResultadosComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'resultados',
      expectedLevel: 'peruser'
    }
  },
  {
    path: 'grafica',
    component: GraficaComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'grafica',
      expectedLevel: 'peruser'
    }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'Admin',
      expectedLevel: 'peradmin'
    }
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'Perfil',
      expectedLevel: 'peruser'
    }
  },
  {
    path: 'usuario',
    component: UsuarioComponent,
    canActivate: [RoleGuardService],
    data: {
      title: 'Usuario',
      expectedLevel: 'peruser'
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RoleGuardService]
})
export class AppRoutingModule { }
