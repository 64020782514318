import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AdminService {
    constructor(public http: HttpClient) {

    }

    getData() {
        return new Promise((resolve, reject) => {
          this.http.get(environment.api + 'admin')
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
        });
      }

      actulizar(datos) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.api + 'admin', datos)
                .subscribe((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }

}
